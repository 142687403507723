import {
  IS_CANARY_PREPROD,
  IS_DEVELOPMENT,
  IS_PREPRODUCTION,
  IS_PRODUCTION,
  IS_STAGING,
} from "shared-utils"

export const LIMIT_ALL_TRANSACTION_VALUE = "ORDER-LIMIT_ALL_TRANSACTION_VALUE"
export const LIMIT_PD_TRANSACTION_VALUE = "ORDER-LIMIT_PD_TRANSACTION_VALUE"
export const PAY_SIMULATION_BUTTON = "PAYMENT-PAY_SIMULATION_BUTTON"
export const REMOVE_REGION_FORM_ADDRESS = "LOGISTIC-REMOVE_REGION_FORM_ADDRESS"
export const VALIDATE_LOGISTIC_KILL_SWITCH =
  "PRODUCT-VALIDATE_LOGISTIC_KILL_SWITCH"
export const SELLER_ADDENDUM = "ORDER-ALLOW_SELLER_TO_PROPOSE_ADDENDUM"
export const PAYMENT_COA_FLOW = "PAYMENT_COA_FLOW"
export const PAYMENT_NEW_FLOW = "PAYMENT-NEW_FLOW"
export const HIDE_CANCEL_ORDER_BUTTON = "ORDER-HIDE_CANCEL_ORDER_BUTTON"
export const ADDENUM_QTY_ZERO = "ORDER-ADDENDUM_QUANTITY_ZERO"
export const SHOW_BYPASS_SAKTI_SIMULATION = "PAYMENT-BYPASS_SAKTI_INTEGRATION"
export const ORDER_RATING_REVIEW = "PRODUCT-ORDER_RATING_REVIEW"
export const USE_GOOGLE_MAP_VERSION_2 = "USE_GOOGLE_MAP_VERSION_2"
export const BLACKLIST_CONSUMER_PLATFORM_INTEGRATION =
  "BLACKLIST-BUYER_CONSUMER_PLATFORM_INTEGRATION"
export const VALIDATION_BUTTON_IS_LOCK_PAYMENT =
  "PAYMENT-VALIDATION_IS_LOCK_PAYMENT"
export const MULTISHIPMENT = "MULTISHIPMENT"
export const PAYMENT_UPLOAD_TAX_DEDUCTION = "PAYMENT-UPLOAD_TAX_DEDUCTION"
export const PAYMENT_TAX_AMOUNT_RECOMMENDATION =
  "PAYMENT-TAX_AMOUNT_RECOMMENDATION"
export const MULTISHIPMENT_MP =
  IS_DEVELOPMENT || IS_STAGING ? "MULTISHIPMENT_DEV_MP" : "MULTISHIPMENT"
export const MULTISHIPMENT_REVIEW_PPK_STANDALONE =
  "MULTISHIPMENT-REVIEW_PPK_STANDALONE"
export const MULTISHIPMENT_ZERO_SHIPMENT_FEE = "MULTISHIPMENT-ZERO_SHIPMENT_FEE"
export const PRODUCT_PRINCIPAL_SECTORAL =
  "MARKETPLACE-PRODUCT_PRINCIPAL_SECTORAL"
export const ENABLE_CONSTRUCTION_MINICOM =
  "MINI-COMPETITION_ENABLE_CONSTRUCTION"
export const MULTISHIPMENT_JASA_DIGITAL = "MULTISHIPMENT_JASA_DIGITAL"
export const BUYER_MINI_COMPETITION_ENABLED_NON_ITEMIZED =
  "BUYER-MINI-COMPETITION-ENABLED_NON_ITEMIZED"
export const MULTISHIPMENT_MULTIPAYMENT = "MULTISHIPMENT_MULTIPAYMENT"
export const CHAT_REDIRECT_REVAMP = "CHAT_REVAMP-HOOKS"
export const ORDER_CONFIRMATION_DELIVERY_DOCUMENT =
  "ORDER-SERVICEDIGITAL_CONFIRMATION_DELIVERY_DOCUMENT"
export const MARKETPLACE_OUTSOURCING_FEE = "MARKETPLACE-OUTSOURCING_FEE"
export const MARKETPLACE_CONSOLIDATION_AND_FIX_PRICE =
  "MARKETPLACE-CONSOLIDATION_AND_FIX_PRICE"
export const GRADUALLY_WORKING_PROGRESS = "GRADUALLY-WORKING-PROGRESS"
export const MARKETPLACE_PRODUCT_ACCELERATION =
  "MARKETPLACE-PRODUCT_ACCELERATION"
export const MARKETPLACE_NEW_TAX_CALCULATION = "MARKETPLACE-NEW_TAX_CALCULATION"
export const MARKETPLACE_MINICOM_ADJUSTMENT = "MARKETPLACE-MINICOM_ADJUSTMENT"
export const MARKETPLACE_ADJUSTMENT_DOWN_PAYMENT =
  "MARKETPLACE-ADJUSTMENT_DOWN_PAYMENT"
export const MARKETPLACE_ADJUSTMENT_RETENSI = "MARKETPLACE-ADJUSTMENT_RETENSI"
export const DOCUMENT_STATUS_FIELD_QUERY =
  "ORDER_DOCUMENTS_PAYMENT_STATUS_FIELD_QUERY"
export const MARKETPLACE_SIPASTI_INFO_ADJUSTMENT =
  "MARKETPLACE-SIPASTI_INFO_ADJUSTMENT"

export const MARKETPLACE_SIPASTI = "MARKETPLACE-SIPASTI"

export const FLAG_REPORT_PRODUCT = !IS_PRODUCTION || IS_CANARY_PREPROD

export const IS_PREPROD_OR_STAGING =
  IS_STAGING || IS_PREPRODUCTION || IS_DEVELOPMENT

export const IS_DEVELOPMENT_OR_STAGING = IS_STAGING || IS_DEVELOPMENT

export const ENTERPRISE_VALIDATION = "ACCOUNT-NONKLPD_ENTERPRISE_VALIDATION"
export const PAYMENT_DETAIL_BILL_AMOUNT = "PAYMENT-BILL_AMOUNT"
export const PAYMENT_TEST_PAYMENT_FLOW_OF_KKI =
  "PAYMENT-TOGGLE_MIDTRANS_INTEGRATION_ENV"
export const PAYMENT_NEW_LSPD_FLOW = "PAYMENT-NEW_LSPD_FLOW"

export const SHOW_PINPOINT_LEAFLET = "ACCOUNT-PINPOINT_LEAFLET"

export const LOGISTIC_IGNORE_DISTRICT_VILLAGE =
  "LOGISTIC_IGNORE-DISTRICT-VILLAGE"
