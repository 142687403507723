import { type KeyboardEvent, type WheelEvent } from "react"
import { HELPDESK_URL } from "shared-utils"

import { ACCOUNT_PPN_URL, CDN_URL, ZENDESK_URL } from "@/config/client"

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes"

  const units = ["bytes", "Kb", "Mb", "Gb", "Tb"]
  const dm = decimals < 0 ? 0 : decimals

  let convertedBytes = bytes
  let i = 0

  while (convertedBytes >= 1024 && i < units.length - 1) {
    convertedBytes /= 1024
    i++
  }

  const selectedUnit = units[i]

  if (typeof selectedUnit === "string") {
    return `${convertedBytes.toFixed(dm)} ${selectedUnit}`
  }

  return "0 Bytes"
}

export const formatSeconds = (e: number) => {
  const h = Math.floor(e / 3600)
      .toString()
      .padStart(2, "0"),
    m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, "0"),
    s = Math.floor(e % 60)
      .toString()
      .padStart(2, "0")

  return h + ":" + m + ":" + s
}

export const trademarkUrl = (applicationNumber: string) =>
  `https://pdki-indonesia.dgip.go.id/detail/${applicationNumber}?type=trademark`

export const kbkiUrl = (kbkiCode: string) =>
  `https://klasifikasi.web.bps.go.id/app/view/kbki2015/${kbkiCode}`

export const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
  if ((event.altKey || event.metaKey) && event.key === "a") {
    return
  }
  if (
    !/^\d$/.test(event.key) &&
    event.key !== "Backspace" &&
    event.key !== "ArrowLeft" &&
    event.key !== "ArrowRight"
  ) {
    event.preventDefault()
  }
}

export const handleOnWheel = (event: WheelEvent<HTMLInputElement>) => {
  event.currentTarget.blur()
}

export const BANNERS_FILE_NAME = [
  "banner-v5.jpg",
  "banner-batas-pembayaran.png",
  "banner-UP-LS.png",
  "banner-minikom.jpeg",
  "banner-1.png",
  "banner-2.png",
]

export const getBannerImages = BANNERS_FILE_NAME.map(
  (file) => `${CDN_URL}/buyer-banner/${file}`
)

export const getMonthShortName = (monthNo: number) => {
  const date = new Date()
  date.setMonth(monthNo - 1)

  return date.toLocaleString("id", { month: "short" })
}

export const topSixLocationList = [
  {
    name: "DKI Jakarta",
    areaCode: "31.01,31.71,31.72,31.73,31.74,31.75",
  },
  {
    name: "Jabodetabek",
    areaCode:
      "31.01,31.71,31.72,31.73,31.74,31.75,32.01,32.71,32.76,36.03,36.71,36.74,32.16,32.75",
  },
  { name: "Bandung", areaCode: "32.73,32.04,32.17" },
  { name: "Jogjakarta", areaCode: "34.71" },
  { name: "Semarang", areaCode: "33.22,33.74" },
  { name: "Surabaya", areaCode: "35.78" },
]

export const sellerTypeFilterItems = [
  { key: "UMKK", name: "UMKK" },
  { key: "NON_UMKK", name: "Non UMKK" },
  // { key: "OFFICIAL_VENDOR", name: "Official Vendor" },
]

export const shipmentFilterItems = [
  { key: "EXPRESS", name: "Next Day" },
  { key: "REGULAR", name: "Reguler" },
  { key: "CARGO", name: "Kargo" },
  { key: "CUSTOM", name: "Kurir Penyedia" },
]

export const productTypeFilterItems = [
  { key: "PHYSICAL", name: "Barang" },
  { key: "SERVICE", name: "Jasa" },
  { key: "DIGITAL", name: "Produk Digital" },
]

export const checkboxFilterItems = [
  ...productTypeFilterItems,
  ...sellerTypeFilterItems,
  ...shipmentFilterItems,
]

export const maxTkdnBmpFilterValue = 115

export const maxSelectedAreaCodesFilter = 10

export const headerFooterUrls = {
  tnc: HELPDESK_URL.syarat_dan_ketentuan_e_katalog_v6,
  policy_privacy: `${HELPDESK_URL.kebijakan_privasi_e_katalog_v6}`,
  pdn_dashboard: "https://data-pdn.inaproc.id/public/page/id/860",
  help_center: ZENDESK_URL,
  buyer_guide: HELPDESK_URL.panduan_pp_ppk,
  seller_guide: HELPDESK_URL.panduan_penyedia,
  faq_buyer: `${ZENDESK_URL}/categories/6791250801935-Pembeli`,
  faq_seller: `${ZENDESK_URL}/categories/7006427858447`,
  whatsapp:
    "https://wa.me/628111557709?text=Halo%20LKPP%20team%20aku%20ada%20pertanyaan%2C%20apa%20bisa%20dibantu%20%3F",
  e_office: "https://eoffice.lkpp.go.id/persuratan",
  faq: HELPDESK_URL.landing,
  user_guide: `${ZENDESK_URL}/categories/7269867824015`,
  video_tutorial: `${ZENDESK_URL}/categories/9173938142607`,
  rules: `${ZENDESK_URL}/sections/11107104099599-Peraturan`,
  activity_info: `${ZENDESK_URL}/sections/10472981517071-Info-Kegiatan`,
  publication: `${ZENDESK_URL}/sections/10473006988047-Publikasi`,
  category_info: `${ZENDESK_URL}/sections/10785509365903-Info-Kategori`,
  surat_edaran: `${ZENDESK_URL}/sections/11107150980239-Surat-Edaran`,
  prod_hukum: `${ZENDESK_URL}/categories/9038110434703-Produk-Hukum`,
  news: `${ZENDESK_URL}/categories/10472942353935-Berita`,
  seller_info: `${ZENDESK_URL}/sections/11107275035151`,
  pp_ppk_info: `${ZENDESK_URL}/sections/11107291728655-Info-Kegiatan-PP-PPK`,
  announcement: `${ZENDESK_URL}/sections/11107275848207-Pengumuman`,
}

export const bsreVerificationHelps = {
  cs: "https://t.me/infobsre",
  guideRepository: "https://bsre.bssn.go.id/repository/juknis",
  guideVideo: "https://www.youtube.com/watch?v=QNs3lW8UMaI",
}

export const headerNavbarMenu = [
  {
    name: "Syarat & Ketentuan",
    link: headerFooterUrls.tnc,
  },
  {
    name: "Kebijakan Privasi",
    link: headerFooterUrls.policy_privacy,
  },
  { name: "Dashboard PDN", link: headerFooterUrls.pdn_dashboard },
  { name: "Pusat Bantuan", link: headerFooterUrls.help_center },
]

export const headerNavbarMenuNew = [
  {
    name: "Produk Hukum",
    link: "#",
    links: [
      {
        name: "Syarat & Ketentuan",
        link: headerFooterUrls.tnc,
      },
      {
        name: "Kebijakan Privasi",
        link: headerFooterUrls.policy_privacy,
      },
      {
        name: "Peraturan",
        link: headerFooterUrls.rules,
      },
      {
        name: "Surat Edaran",
        link: headerFooterUrls.surat_edaran,
      },
      {
        name: "Produk Hukum Selengkapnya",
        link: headerFooterUrls.prod_hukum,
      },
    ],
  },
  {
    name: "Dashboard PDN",
    link: headerFooterUrls.pdn_dashboard,
    links: [],
  },
  {
    name: "Pusat Bantuan",
    link: "#",
    links: [
      {
        name: "Panduan Pengguna",
        link: headerFooterUrls.user_guide,
      },
      {
        name: "FAQ",
        link: headerFooterUrls.faq,
      },
      {
        name: "Video Tutorial",
        link: headerFooterUrls.video_tutorial,
      },
    ],
  },
  {
    name: "Berita",
    link: "#",
    links: [
      {
        name: "Info Kegiatan Penyedia",
        link: headerFooterUrls.seller_info,
      },
      {
        name: "Info Kegiatan PP / PPK",
        link: headerFooterUrls.pp_ppk_info,
      },
      {
        name: "Pengumuman",
        link: headerFooterUrls.announcement,
      },
      {
        name: "Berita Selengkapnya",
        link: headerFooterUrls.news,
      },
    ],
  },
]

export const hiddenHeaderAddressPath = [
  "/checkout",
  "/direct-checkout",
  "/form-negotiation",
]

export const sortProductLabelsByName = (arr: string[], label: string) => {
  return arr.sort((a, b) => {
    if (a === label) return -1
    if (b === label) return 1
    return 0
  })
}

// access_management url is to be adjusted on squad account ready
export const getAccountPpnUrl = (personaId?: string) => {
  return {
    access_management: `${ACCOUNT_PPN_URL}/access-management`,
    setting: `${ACCOUNT_PPN_URL}/settings/account-profile?personaId=${personaId}`,
  }
}

export const MAX_RATING_VALUE = "5.0"

export const starsArray = Array.from({
  length: parseInt(MAX_RATING_VALUE),
})
  .map((_, i) => {
    return i + 1
  })
  .reverse()
